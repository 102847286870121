import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiResponse, Tag } from '@shared/types';
import { catchError, map, Observable, of, throwError } from 'rxjs';

export type AuthUserResponse = {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly tags: ReadonlyArray<Tag>;
};

export enum SocialLoginEnum {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly #http: HttpClient = inject(HttpClient);

  postLogin$(username: string, password: string, recaptcha: string): Observable<AuthUserResponse> {
    return this.#http.post<ApiResponse<AuthUserResponse>>('auth/login/password/verify', { username, password, recaptcha }).pipe(
      map((res: ApiResponse<AuthUserResponse>) => res.data),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  postForgottenPassword$(email: string): Observable<ApiResponse<void>> {
    return this.#http.post<ApiResponse<void>>('auth/password/reset', { email }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  postResetPassword$(password: string, token: string): Observable<ApiResponse<void>> {
    return this.#http.post<ApiResponse<void>>('auth/password-reset/change', { password, token }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  postLogout$(): Observable<ApiResponse<void>> {
    return this.#http.post<ApiResponse<void>>('auth/logout', {}).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  getResetPasswordCheck$(token: string): Observable<ApiResponse<void>> {
    return this.#http.get<ApiResponse<void>>(`auth/password-reset/check/${token}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  getRegistrationVerifyCheck$(token: string): Observable<ApiResponse<void>> {
    return this.#http.get<ApiResponse<void>>(`auth/verification/verify/${token}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  getUserInfo$(): Observable<AuthUserResponse> {
    return this.#http.get<ApiResponse<AuthUserResponse>>('profile').pipe(
      map((res: ApiResponse<AuthUserResponse>) => res.data),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  getIsLoggedIn$(): Observable<{ isLoggedIn: boolean }> {
    return this.#http.get<ApiResponse<{ isLoggedIn: boolean }>>('is-logged-in').pipe(
      map((res: ApiResponse<{ isLoggedIn: boolean }>) => res.data),
      catchError(() => of({ isLoggedIn: false }))
    );
  }

  getSocialLoginUrl$(platform: SocialLoginEnum): Observable<{ url: string }> {
    return this.#http.get<ApiResponse<{ url: string }>>(`auth/login/${platform}/authorization-url`).pipe(
      map((res: ApiResponse<{ url: string }>) => res.data),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }
}
